import React from "react"
import Box from "@material-ui/core/Box"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"

export default function Thanks({ data }) {
  return (
    <>
      <Header />
      <MobileNav />
      <Layout>
        <Box
          mx="auto"
          width={{ xs: 2 / 3, sm: 1 / 2, md: 1 / 3 }}
          pt={{ xs: 5, sm: 8, md: 8, lg: 8 }}
        >
          <Img fluid={data.agreement.childImageSharp.fluid} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          alignItems="center"
          py={{ xs: 5, sm: 5, md: 6, lg: 6 }}
        >
          <Box
            order={1}
            width={4 / 5}
            textAlign="center"
            fontSize="h1.fontSize"
            fontWeight={500}
            letterSpacing={1.5}
            lineHeight={1.5}
          >
            Thank You
          </Box>
          <Box
            order={2}
            width={4 / 5}
            fontSize="h3.fontSize"
            fontWeight={500}
            letterSpacing={1.5}
            lineHeight={1.5}
            my={5}
            textAlign="center"
          >
            We'll contact your shortly and we look forward to working with you.
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    agreement: file(relativePath: { eq: "agreement.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
